import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';

// import CookieBanner from 'react-cookie-banner';
import { Offline, Online } from "react-detect-offline";

import NavBar from './NavBar';
import Overview from '../../Overview/components/Overview';
import Timetable from '../../Timetable/components/Timetable';
import Settings from '../../Settings/components/Settings';

import * as moduleActions from '../actions';
import {
    APP_VIEW_OVERVIEW, APP_VIEW_CALENDAR, APP_VIEW_SETTINGS
} from '../constants';
import ErrorBoundary from './ErrorBoundary';

const mapStateToProps = (state) => ({
    state: state.app,
    appView: state.app.appView,
    appIsLoading: state.app.isLoading,
    isNewUpdateAvailable: state.app.isNewUpdateAvailable,
})

const mapDispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(moduleActions, dispatch),
    dispatch
})

const App = ({ state, appView, isNewUpdateAvailable, appActions }) => {
    const [isMaintenance, setMaintenance] = useState(0);
    // Debug Only
    if (process.env['REACT_APP_ENV'] !== 'production') {
        console.debug('debug-log', state);
    }

    useEffect(() => {
        const apiUrl = process.env['REACT_APP_SALAH_TIMEZ_API_URL'];
        const apiKey = process.env['REACT_APP_API_KEY'];
        const config = {
            headers: {
                'x-api-key': apiKey,
            }
        }

        appActions.toggleCookieConsent(localStorage.getItem('cookieConsent') ?? true);
        appActions.toggleOfflineMode(localStorage.getItem('offlineUsage') ?? true);

        axios.get(`${apiUrl}/config`, config)
            .then((res) => {
                const meta = res.data;
                try {
                    setMaintenance(JSON.parse(meta.is_maintenance));
                } catch (err) {
                    console.error('invalid value')
                }

                appActions.updateMetaInfo(meta)
            })
            .catch((err) => {
                console.error(err);
                // Sentry.captureException(err);
            })
    }, [])
    // Render
    let maintenanceBanner = '';
    let renderedView = '';
    let newAppVersion = (
        <Offline>
            <div className="banner warn" onClick={() => window.location.reload()}>
                Offline Mode
            </div>
        </Offline>
    )
    if (isNewUpdateAvailable) {
        newAppVersion = (
            <Online>
                <div className="banner notice" onClick={() => window.location.reload()}>
                    New version is available! Click here to update
                </div>
            </Online>
        )
    }

    if (isMaintenance) {
        maintenanceBanner = (
            <div className="banner warn">
                We will be carrying out maintenance tonight that may cause disruption
            </div>
        )
    }

    switch (appView) {
        case APP_VIEW_CALENDAR:
            renderedView = <Timetable />
            break;
        case APP_VIEW_SETTINGS:
            renderedView = <Settings />
            break; 
        default:
        case APP_VIEW_OVERVIEW:
            renderedView = <Overview />;
            break;
        
    }
    
    return (
        <div className="app">
            {/* <CookieBanner
                message="We use cookies, just to track usage and enhance experience, you can change this in the settings"
                cookie="user-has-accepted-cookies"
                disableStyle={true}
            /> */}
            { maintenanceBanner }
            {/* <div className="website-warning">
                This app is optimized for usage by phones and tablets, desktop version coming soon!
            </div> */}
            { newAppVersion }
            { renderedView }
            <NavBar />
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
