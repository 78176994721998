import React from 'react';
// import ReactGA from 'react-ga';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import store from "./modules/store/store";

import App from './modules/root/components/App';
import './sass/app.scss';

import * as serviceWorker from './serviceWorker';
import swConfig from './swConfig'
import ErrorBoundary from './modules/root/components/ErrorBoundary';

import config from '../package.json';

// let cookieConsent = localStorage.getItem('cookieConsent') ?? true;
// if (cookieConsent && process.env['REACT_APP_GA_KEY']) {
//     ReactGA.initialize(process.env['REACT_APP_GA_KEY']);
//     ReactGA.pageview(window.location.pathname + window.location.search);
// }
const SENTRY_DSN = process.env['REACT_APP_SENTRY_KEY'];
if (SENTRY_DSN) {
    console.info('Sentry Initialized');
    Sentry.init({
        dsn: SENTRY_DSN,
        release: config['version'],
    });
} else {
    console.warn('Sentry not initialized');
}


ReactDOM.render(
    <ErrorBoundary>
        <Provider store={store}>
            <App />
        </Provider>
    </ErrorBoundary>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register(swConfig);
