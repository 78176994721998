export const VIEW_OPTION_THEME = {
    accentColor: '#1E788C',
    floatingNav: {
      background: 'rgba(185, 212, 214, 0.9)',
      chevron: 'transparent',
      color: '#333',
    },
    headerColor: '#1E788C',
    selectionColor: '#1E788C',
    textColor: {
      active: '#FFF',
      default: '#333',
    },
    todayColor: '#1E788C',
    weekdayColor: '#1E788C',
};
export const VIEW_OPTION_LOCALE = {
    blank: 'Select a date...',
    headerFormat: 'ddd, MMMM Do',
    todayLabel: {
      long: 'Today',
    },
    weekdays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekStartsOn: 0,
  };
export const VIEW_OPTION_DISPLAY = {
    hideYearsOnSelect: true,
    layout: 'portrait',
    shouldHeaderAnimate: true,
    showHeader: false,
    showMonthsForYears: false,
    showOverlay: true,
    showTodayHelper: true,
    showWeekdays: true,
    todayHelperRowOffset: 4,
};