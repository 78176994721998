import * as moduleActions from './actions';
import { APP_VIEW_OVERVIEW } from './constants';

const initialState = {
    appView: APP_VIEW_OVERVIEW,
    isNewUpdateAvailable: false,
    isLoading: true,
    meta: {},
    settings: {
        cookieConsent: true,
        offlineUsage: true,
    },
}

export function app (state = initialState, action) {
    switch (action.type) {
        case moduleActions.NEW_APP_VERSION:
            return {
                ...state,
                isNewUpdateAvailable: true
            }
        case moduleActions.LOAD_META_INFO:
            return {
                ...state,
                meta: {
                    ...action.meta
                },
            }
        case moduleActions.TOGGLE_APP_VIEW:
            return {
                ...state,
                appView: action.newView
            }
        case moduleActions.TOGGLE_COOKIE_CONSENT:
            return {
                ...state,
                settings: {
                    cookieConsent: action.consent,
                    offlineUsage: state.settings.offlineUsage,
                }
            }
        case moduleActions.TOGGLE_OFFLINE_MODE:
            return {
                ...state,
                settings: {
                    cookieConsent: state.settings.cookieConsent,
                    offlineUsage: action.isOffline
                }
            }
        default:
            return state
    }
}