import React from 'react';

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0'+minutes : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
}

const Salah = (props) => {
    const { salah, start } = props.salah;

    return (
        <div className="salah">
            <span className="prayer-name">{salah.charAt(0).toUpperCase() + salah.substring(1)}</span>
            <span className="prayer-time">{formatAMPM(start)}</span>
        </div>
    );
};

export default Salah;