import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import * as Sentry from '@sentry/browser';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMosque, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import SwipeableViews from 'react-swipeable-views';
import {
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";
import Countdown from 'react-countdown';

import Card from './Card';
import DayOfSalahs from '../DayOfSalahs';
import DayOfWeather from '../DayOfWeather';

const mapStateToProps = (state) => ({
    appIsLoading: state.app.isLoading,
    meta: state.app.meta,
});

const Overview = ({ meta, appIsLoading }) => {
    const [isPromptOpen, togglePrompt] = useState(false);
    const [currentDay, setCurrentDay] = useState({});
    const [weekOfSalahs, setWeek] = useState([]);
    const [weekOfWeathers, setWeathers] = useState([]);
    const [viewCountdown, toggleCountdown] = useState(false);

    const announcement = 'announcement' in meta ? meta.announcement : 'Stay At Home. Protect the NHS. Save Lives';
    const announcementText = 'announcement_text' in meta ? meta.announcement_text : '';

    useEffect(() => {
        const rawWeekOfSalahs = localStorage.getItem('weekOfSalahs');

        if (rawWeekOfSalahs) {
            const weekOfSalahs = JSON.parse(rawWeekOfSalahs).map(dayOfSalah => new DayOfSalahs(dayOfSalah));
            const currentDayRaw = DayOfSalahs.getTodaysTimes(weekOfSalahs);
    
            // Action to add to global state
            setCurrentDay(currentDayRaw);
            setWeek(weekOfSalahs);
        }

        const apiUrl = process.env['REACT_APP_SALAH_TIMEZ_API_URL'];
        const apiKey = process.env['REACT_APP_API_KEY'];
        const baseUrl = `${apiUrl}/prayer-times`;
        let url = `${baseUrl}/week?masjid_id=1`;
        
        const config = {
            headers: {
                'x-api-key': apiKey,
            }
          }
        
        axios.get(url, config)
            .then((res) => {
                const weekOfSalahs = [];
                localStorage.setItem('weekOfSalahs', JSON.stringify(res.data));
        
                for (const salah in res.data) {
                    const dayOfSalah = new DayOfSalahs(res.data[salah])
                    weekOfSalahs.push(dayOfSalah)
                }
    
                const currentDayRaw = DayOfSalahs.getTodaysTimes(weekOfSalahs);
                setCurrentDay(currentDayRaw);
                setWeek(weekOfSalahs)
            })
            .catch((err) => {
                console.error(err);
                Sentry.captureException(err)
            })
        
            axios.get('https://api.openweathermap.org/data/2.5/onecall?lat=51.509865&lon=-0.118092&units=metric&appid=3389ffc704505cea62b3c82c93a6d346')
                .then((res) => {
                    const weekOfWeathers = [];

                    for (const day in res.data['daily']) {
                        const dayOfWeather = new DayOfWeather(res.data['daily'][day])
                        weekOfWeathers.push(dayOfWeather)
                    }

                    setWeathers(weekOfWeathers);
                })
                .catch((err) => {
                    console.error(err)
                    // Sentry.captureException(err)
            })
    }, []);

    const weekOfSalahCards = [];
    let renderedView = (
            <div className="loading">
                <div className="lds-dual-ring" />
            </div>
        );

    for (const i in weekOfSalahs) {
        weekOfSalahCards.push(<Card dayOfSalah={weekOfSalahs[i]} weekOfWeathers={weekOfWeathers} key={i} />)
    }

    if (weekOfSalahCards.length > 1) {
        renderedView = (
            <SwipeableViews index={parseInt(currentDay.todaysIndex)}>
                { weekOfSalahCards }
            </SwipeableViews>
        )
    }

    if (!appIsLoading && weekOfSalahCards.length === 0) {
        renderedView = (
            <div className="loading error">
                <p style={{textAlign: 'center'}}>Somethings gone wrong, try again later!</p>
            </div>
        )
    }

    let countdownRender, countdownBtn = '';
    if ('todaysTimes' in currentDay) {
        const currentSalahName = DayOfSalahs.getCurrentSalah(currentDay.todaysTimes.salahs, currentDay.todaysTimes.sunrise);
        const nextSalah = DayOfSalahs.getNextSalah(currentDay.todaysTimes.salahs, currentDay.todaysTimes.sunrise);

        const renderer = ({ hours, minutes, seconds, completed }) => {
            if (completed) {
              // Render a completed state
              return (
                <div className={`countdown-view ${viewCountdown ? 'active' : ''}`}>
                    <p>Currently {currentSalahName}</p>
                </div>
              );
            } else {
              // Render a countdown
              return (
                  <div className={`countdown-view ${viewCountdown ? 'active' : ''}`}>
                      <p>Currently {currentSalahName}</p>
                      <div className="countdown">
                            <div className="time">
                                {hours}
                                <span className="sub">
                                    hr
                                </span>
                            </div>
                            <div className="time">
                                {minutes}
                                <span className="sub">
                                    min
                                </span>
                            </div>
                            <div className="time">
                                {seconds}
                                <span className="sub">
                                    sec
                                </span>
                            </div>
                    </div>
                    <p>Until {nextSalah.salah}</p>
                  </div>
            );
            }
        };


          if (nextSalah) {
                countdownBtn = (
                        <div className="btn" onClick={() => toggleCountdown(!viewCountdown)}>
                        Countdown
                    </div>
                )
                countdownRender = <Countdown
                    date={nextSalah.begins}
                    renderer={renderer}
                />
        }
    }
    

    return (
        <div className="view--overview">
            <header>
                <h1>Salah Timez</h1>
                <div className="selected-mosque">
                    <FontAwesomeIcon icon={faMosque} />
                    <span>East London Mosque</span>
                </div>
                {countdownBtn}
            </header>
            { <div className={isPromptOpen? 'announcement open' : 'announcement'} onClick={() => {togglePrompt(!isPromptOpen)}}>
                    {announcement}
                    {announcementText ? <span className="text">
                        {announcementText}
                    </span> : ''}
                </div> }
            { countdownRender }
            <div className="prompt">
                <span>Swipe to view next few days</span>
                <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className="cards">
                {renderedView}
            </div>
            <div className="subtext">
                <a href='mailto:salahtimez@deenful.com'>See anything wrong? Click <strong>here</strong> to contact us!</a>
            </div>
            <div className="socials">
                <WhatsappShareButton title={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} url={'https://www.salahtimez.com/'}>
                    <WhatsappIcon size={32} round={true} />
                </WhatsappShareButton>
                <TwitterShareButton title={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} via={'SalahTimez'} url={'https://www.salahtimez.com/'}>
                    <TwitterIcon size={32} round={true} />
                </TwitterShareButton>
                <EmailShareButton subject={'SalahTimez Query'} body={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} url={'https://www.salahtimez.com/'}>
                    <EmailIcon size={32} round={true} />
                </EmailShareButton>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, null)(Overview);