import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';

import Salah from './Salah';


const CurrentDay = (props) => {
    const { dayOfSalah } = props;
    const salahs = [] ;

    if (!dayOfSalah.date) {
        return(<div></div>)
    }

    for (let salah in dayOfSalah.salahs) {
        salahs.push(
            <Salah salah={dayOfSalah.salahs[salah]} key={salah}/>
        )
    }

    return (
        <div className="current-day">
            <div className="times-sub-text">
                <span className="sunrise-sunset">
                    <FontAwesomeIcon icon={faSun} />
                    <span>Sunrise {dayOfSalah.sunrise.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                </span>
                <span className="sunrise-sunset">
                    <FontAwesomeIcon icon={faMoon} />
                    <span>Sunset {dayOfSalah.sunset.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                </span>
            </div>
            <div className="salah-times">
                { salahs }
            </div>
        </div>
    );
};

export default (CurrentDay);
