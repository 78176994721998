import React, { useState } from 'react';

function formatAMPM(date) {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    var strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;

    // if (!date) {
    //     return '';
    // }
    // const mm = date.getUTCMonth() + 1; // getMonth() is zero-based
    // const dd = date.getUTCDate();
    // const hr = date.getHours();
    // const min = date.getMinutes();

    // return [
    //     date.getUTCFullYear(),
    //     (mm > 9 ? '' : '0') + mm,
    //     (dd > 9 ? '' : '0') + dd,
    // ].join('-');
}

const Salah = (props) => {
    const [isOpen, toggleSalah] = useState(false);
    const { currentSalah } = props;
    const { salah, start, startMithl1, jammat } = props.salah;
    const baseClass = currentSalah === salah ? 'salah active': 'salah';
    let asr, renderMithl, className = '';

    if (salah === 'asr') {
        asr = '*2nd Mithl';

        if (startMithl1) {
            renderMithl = (
                <div className='sub-view'>
                    <span className="sub-name">1st Mithl</span>
                    <span className="sub-time">{formatAMPM(startMithl1)}</span>
                </div>
            )
        }
    }

    // Ramadan only
    // if (salah === 'fajr') {
    //     asr = 'Suhur Ends';
    //     className='bubble '
    // }

    // if (salah === 'maghrib') {
    //     asr = 'Iftar';
    //     className='bubble '
    // }

    return (
        <div className={isOpen ? `${baseClass} expanded` : baseClass} onClick={() => toggleSalah(!isOpen)} key={salah}>
            <div className='main-view'>
                <span className="prayer-name">{salah.charAt(0).toUpperCase() + salah.substring(1)} <span className={`${className} sub-text`}>{asr}</span></span>
                <span className="prayer-time">{formatAMPM(start)}</span>
            </div>
            {renderMithl}
            <div className='sub-view'>
                <span className="sub-name">Jammat</span>
                <span className="sub-time">{formatAMPM(jammat)}</span>
            </div>
        </div>
    );
};

export default Salah;