import React, { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import config from '../../../../package.json';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Switch from "react-switch";
import {
    WhatsappShareButton,
    WhatsappIcon,
    TwitterShareButton,
    TwitterIcon,
    EmailShareButton,
    EmailIcon
} from "react-share";

import * as moduleActions from '../../root/actions';


const mapStateToProps = (state) => ({
    settings: state.app.settings
});

const mapDispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(moduleActions, dispatch),
    dispatch
})

const Settings = (props) => {
    const { settings, appActions } = props;
    const [titleView, updateTitle] = useState('');
    const [textView, updateView] = useState('');

    return (
        <div className="view--settings">
            <header>
                <h1>Settings</h1>
            </header>
            <div className="settings">
                <div className="setting">
                    <div className="text">
                        <p>Offline usage</p>
                        <p className="sub-text">
                            Ability to use the app without the internet
                        </p>
                    </div>
                    <Switch
                        checked={settings.offlineUsage}
                        onChange={(k) => {appActions.toggleOfflineMode(k)}}
                        onColor="#B9D4D6"
                        onHandleColor="#1E788C"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                    />
                </div>
                <div className="setting">
                    <div className="text">
                        <p>Cookie consent usage</p>
                        <p className="sub-text">
                            This app uses cookies to enhance the user experience
                        </p>
                    </div>
                    <Switch
                        checked={settings.cookieConsent}
                        onChange={(k) => {appActions.toggleCookieConsent(k)}}
                        onColor="#B9D4D6"
                        onHandleColor="#1E788C"
                        handleDiameter={30}
                        uncheckedIcon={false}
                        checkedIcon={false}
                        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                        height={20}
                        width={48}
                        className="react-switch"
                        id="material-switch"
                    />
                </div>
            </div>
            <div className="socials">
                <div>
                    <h1>Share with your friends</h1>
                    <div className="buttons">
                        <WhatsappShareButton title={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} url={'https://www.salahtimez.com/'}>
                            <WhatsappIcon size={32} round={true} />
                        </WhatsappShareButton>
                        <TwitterShareButton title={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} via={'SalahTimez'} url={'https://www.salahtimez.com/'}>
                            <TwitterIcon size={32} round={true} />
                        </TwitterShareButton>
                        <EmailShareButton subject={'SalahTimez Query'} body={'Check out SalahTimez\nLondon Salah Times at a glance\nJust save/install from your browser. No need to download\n'} url={'https://www.salahtimez.com/'}>
                            <EmailIcon size={32} round={true} />
                        </EmailShareButton>
                    </div>
                </div>
                <div>
                    <h1>Contact us</h1>
                    <div>
                        <a href='mailto:salahtimez@deenful.com'>Email us at salahtimez@deenful.com</a>
                        <a href="https://twitter.com/SalahTimez" target="blank">Tweet us at @SalahTimez</a>
                    </div>
                </div>
                <div className="button-gorup">
                    <a onClick={() => { updateTitle("How to Install"); updateView('Go to the options button in your browser and press "Add to homescreen"') }}>How to install</a>
                </div>
            </div>
            <div className="footer">
                <div className="line" />
                <div className="footer-items">
                    <a onClick={() => { updateTitle(""); updateView("") }} href="https://www.deenful.com/?ref=salahtimez" target="blank">
                        Product of Deenful.com
                    </a>
                </div>
                <div className="footer-items">
                    <span className={titleView === 'Privacy Policy' ? 'active' : ''} onClick={() => { updateTitle("Privacy Policy"); updateView("Cookies are required to use salahtimez.com. A cookie is a small amount of data, which often includes an anonymous unique identifier, that is sent to your browser from a web site’s computers and stored on your computer’s hard drive."); }}>
                    Privacy Policy
                    </span>
                    <span>
                        &middot;
                    </span>
                    <span className={titleView === 'Disclaimer' ? 'active' : ''} onClick={() => { updateTitle("Disclaimer"); updateView("Information provided on this site is WITHOUT WARRANTY or guarantee of accuracy use of this information is strictly voluntary, and reliance on it should only be undertaken after an independent review of its accuracy, completeness, efficiency, and timeliness.Timing on this app are shown as per www.eastlondonmosque.org.uk"); }}>
                       Disclaimer
                    </span>
                    <span>
                        &middot;
                    </span>
                    <span onClick={() => {  updateTitle(""); updateView("") }}>
                        V {config['version']}
                    </span>
                </div>
            </div>
            <div className="text-view">
                {titleView ? (<div onClick={() => { updateTitle(''); updateView('');}}><FontAwesomeIcon icon={faTimes} /></div>) : ''}
                <h1 className="title">
                    {titleView}
                </h1>
                <p>
                    { textView }
                </p>
            </div>
        </div>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);