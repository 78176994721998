import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStream, faCalendarAlt, faCog } from '@fortawesome/free-solid-svg-icons';

import * as moduleActions from '../actions';
import { APP_VIEW_OVERVIEW, APP_VIEW_CALENDAR, APP_VIEW_SETTINGS } from '../constants';

const mapStateToProps = (state) => ({
    appView: state.app.appView,
})

const mapDispatchToProps = (dispatch) => ({
    appActions: bindActionCreators(moduleActions, dispatch),
    dispatch
})

const NavBar = ({ appView, appActions }) => {
    
    return (
        <div className="nav-bar">
            <nav>
                <ul>
                    <li>
                        <div onClick={() => appActions.toggleAppView(APP_VIEW_OVERVIEW)} className={appView === APP_VIEW_OVERVIEW ? 'nav-icon active' : 'nav-icon'}>
                            <FontAwesomeIcon icon={faStream} />
                            <span>Overview</span>
                        </div>
                    </li>
                    <li>
                        <div onClick={() => appActions.toggleAppView(APP_VIEW_CALENDAR)} className={appView === APP_VIEW_CALENDAR ? 'nav-icon active' : 'nav-icon'}>
                            <FontAwesomeIcon icon={faCalendarAlt} />
                            <span>Timetable</span>
                        </div>
                    </li>
                    <li>
                        <div onClick={() => appActions.toggleAppView(APP_VIEW_SETTINGS)} className={appView === APP_VIEW_SETTINGS ? 'nav-icon active' : 'nav-icon'}>
                            <FontAwesomeIcon icon={faCog} />
                            <span>Settings</span>
                        </div>
                    </li>
                </ul>
            </nav>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
