

export default class DayOfSalahs{
    constructor(apiOutput) {
        const dt =  new Date(apiOutput.date).setHours(0);
        this.date =  new Date(dt);
        this.hijriDate = apiOutput.hijri_date.string;
        this.sunrise = new Date(apiOutput.sunrise.replace(' ', 'T'));
        this.sunset = new Date(apiOutput.sunset.replace(' ', 'T'));
        const salahsFormatted = []
        for (const salah in apiOutput.prayer_times) {
            if (salah =='asr') {
                salahsFormatted.push({
                    salah: salah,
                    start: new Date(apiOutput.prayer_times[salah].start.mithl_2.replace(' ', 'T')),
                    startMithl1: new Date(apiOutput.prayer_times[salah].start.mithl_1.replace(' ', 'T')),
                    jammat: new Date(apiOutput.prayer_times[salah].jammat.replace(' ', 'T'))
                })
            } else if (salah == 'fajir') {
                salahsFormatted.push({
                    salah: 'fajr',
                    start: new Date(apiOutput.prayer_times[salah].start.replace(' ', 'T')),
                    jammat: new Date(apiOutput.prayer_times[salah].jammat.replace(' ', 'T'))
                })
            } else {
                salahsFormatted.push({
                    salah: salah,
                    start: new Date(apiOutput.prayer_times[salah].start.replace(' ', 'T')),
                    jammat: new Date(apiOutput.prayer_times[salah].jammat.replace(' ', 'T'))
                })
            }
        }
        this.salahs = salahsFormatted;
    }

    static getTodaysTimes(daysOfSalahs) {
        const today = new Date();
        let todaysTimes;
        let todaysIndex = 0

        for (const day in daysOfSalahs) {
            if (today.getDate() === daysOfSalahs[day].date.getDate() && today.getMonth() === daysOfSalahs[day].date.getMonth()) {
                todaysTimes = daysOfSalahs[day];
                todaysIndex = day;
            }
        }

        return {todaysTimes, todaysIndex}
    }

    static getTomorrowsTimes(daysOfSalahs) {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1)
        let tomorrowTimes;
        let tomorrowIndex = 0

        for (const day in daysOfSalahs) {
            if (tomorrow.getDate() === daysOfSalahs[day].date.getDate() && tomorrow.getMonth() === daysOfSalahs[day].date.getMonth()) {
                tomorrowTimes = daysOfSalahs[day];
                tomorrowIndex = day;
            }
        }

        return {tomorrowTimes, tomorrowIndex}
    }

    static getCurrentSalah(salahs, sunrise) {
        const salahObj = {}

        for (let salah in salahs) {
            salahObj[salahs[salah].salah] = {
                'begins': salahs[salah].start,
                'salah': salahs[salah].salah
            } 
        }


        const now = new Date();
        let currentSalah = '';
        if (salahObj['fajr'].begins < now && sunrise > now) {
            currentSalah = salahObj['fajr'].salah;
        } else if (sunrise < now && salahObj['zuhr'].begins > now) {
            currentSalah = 'sunrise';
        } else if (salahObj['zuhr'].begins < now && salahObj['asr'].begins > now) {
            currentSalah = salahObj['zuhr'].salah;
        } else if (salahObj['asr'].begins < now && salahObj['maghrib'].begins > now) {
            currentSalah = salahObj['asr'].salah;
        } else if (salahObj['maghrib'].begins < now && salahObj['isha'].begins > now) {
            currentSalah = salahObj['maghrib'].salah;
        } else if (salahObj['isha'].begins < now) {
            currentSalah = salahObj['isha'].salah;
        }

        return currentSalah;
    }

    static getNextSalah(salahs, sunrise) {
        const salahObj = {}

        for (let salah in salahs) {
            salahObj[salahs[salah].salah] = {
                'begins': salahs[salah].start,
                'salah': salahs[salah].salah
            } 
        }

        const now = new Date();
        let nextSalah = salahObj['fajr'];
        if (salahObj['fajr'].begins < now && sunrise > now) {
            nextSalah = salahObj['zuhr']
        } else if (salahObj['zuhr'].begins < now && salahObj['asr'].begins > now) {
            nextSalah = salahObj['asr']
        } else if (salahObj['asr'].begins < now && salahObj['maghrib'].begins > now) {
            nextSalah = salahObj['maghrib']
        } else if (salahObj['maghrib'].begins < now && salahObj['isha'].begins > now) {
            nextSalah = salahObj['isha']
        } else if (salahObj['isha'].begins < now) {
            nextSalah = '';
        }

        return nextSalah;
    }
}