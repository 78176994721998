import React, { useState, useEffect } from 'react';
import axios from 'axios';

import InfiniteCalendar from 'react-infinite-calendar';
import 'react-infinite-calendar/styles.css';

import CurrentDay from './CurrentDay';
import useWindowDimensions from './useWindowDimensions';
import DayOfSalahs from '../../Overview/DayOfSalahs';
import { VIEW_OPTION_DISPLAY, VIEW_OPTION_LOCALE, VIEW_OPTION_THEME } from '../config';


const Timetable = () => {
    const [currentDayOfSalah, updateCurrentDay] = useState(false);
    const apiUrl = process.env['REACT_APP_SALAH_TIMEZ_API_URL'];
    const apiKey = process.env['REACT_APP_API_KEY'];
    const baseUrl = `${apiUrl}/prayer-times`;
    let url = `${baseUrl}/day?masjid_id=1`;
    const config = {
        headers: {
            'x-api-key': apiKey,
        }
    }

    useEffect(() => {
        axios.get(url,config)
            .then((res) => {
                updateCurrentDay(new DayOfSalahs(res.data));
            })
            .catch((err) => {
                console.error(err);
                // Sentry.captureException(err)
            })
    }, [])
    
    const { height } = useWindowDimensions();
    let renderSalah = <div />

    if (currentDayOfSalah) {
        renderSalah = <CurrentDay dayOfSalah={currentDayOfSalah} />
    }
     
    return (
        <div className="view--timetable">
            <header>
                <h1>Timetable</h1>
                {currentDayOfSalah && <span>
                    {currentDayOfSalah.date.toLocaleDateString('en-GB', { year: 'numeric', month: 'long', day: 'numeric' })} | {currentDayOfSalah.hijriDate}
                </span>}
            </header>
            { renderSalah }
            <InfiniteCalendar 
                displayOptions={VIEW_OPTION_DISPLAY} 
                className={'timetable'}
                minDate={new Date(2022, 0, 1)}
                maxDate={new Date(2023, 0, 1)}
                height={height-450}
                locale={VIEW_OPTION_LOCALE}
                theme={VIEW_OPTION_THEME}
                onSelect={(today) => {
                    axios.get(`${url}&date=${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`, config)
                        .then((res) => {
                            updateCurrentDay(new DayOfSalahs(res.data))
                        })
                }}
            />
        </div>
    );
};

export default (Timetable);