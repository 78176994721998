import React, { useEffect, useState, } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faMoon } from '@fortawesome/free-solid-svg-icons';
import DayOfSalahs from '../DayOfSalahs';
import DayOfWeather from '../DayOfWeather';
import Salah from './Salah';

const Card = (props) => {
    const { dayOfSalah, weekOfWeathers } = props;
    
    const options = { dateStyle: 'full' };
    const currentSalah = DayOfSalahs.getCurrentSalah(dayOfSalah.salahs, dayOfSalah.sunrise);
    const currentWeather = DayOfWeather.getTodaysWeather(dayOfSalah.date, weekOfWeathers);
    const salahs = [];
    let weatherHeader = '';
    let weatherDescription = '';

    for (let salah in dayOfSalah.salahs) {
        salahs.push(
            <Salah salah={dayOfSalah.salahs[salah]} key={salah} currentSalah={currentSalah}/>
        )
    }

    if (currentWeather) {
        weatherHeader = (
            <div className="weather--header">
                <img alt="weather " rel="preconnect" src={currentWeather.icon} />
            </div>
        )

        weatherDescription = (
            <div className="weather--description">
                {/* {currentWeather.description} */}
                <span>{currentWeather.dayTemp} &#176; </span>
            </div>
        )
    }

    return (
        <div className="card">
            <div className="card--header">
                <div className="weather">
                    { weatherHeader }
                    { weatherDescription }
                </div>
                <div className="info">
                    <div className="dates-main">
                        <span>{dayOfSalah.date.toLocaleDateString('en-GB', options)}</span>
                        <span>| {dayOfSalah.hijriDate}</span>
                    </div>
                    <div className="times-sub-text">
                        <span className="sunrise-sunset">
                            <FontAwesomeIcon icon={faSun} />
                            <span>Sunrise {dayOfSalah.sunrise.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                        </span>
                        <span className="sunrise-sunset">
                            <FontAwesomeIcon icon={faMoon} />
                            <span>Sunset {dayOfSalah.sunset.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true })}</span>
                        </span>
                    </div>
                </div> 
            </div>
            <div className="card--view">
                {salahs}
            </div>
        </div>
    );
};

export default (Card);