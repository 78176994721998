

export default class DayOfWeather{
    constructor(apiOutput) {
        const dt =  new Date(apiOutput.dt * 1000).setHours(0)
        this.date =  new Date(dt)
        this.description = apiOutput['weather'][0].description;
        this.icon = `https://openweathermap.org/img/wn/${apiOutput['weather'][0].icon}@2x.png`;
        this.dayTemp = apiOutput['temp'].day.toFixed(1);
    }

    static getTodaysWeather(today, daysOfWeather) {
        let todaysWeather;

        for (const day in daysOfWeather) {
            if (today.getDate() === daysOfWeather[day].date.getDate() && today.getMonth() === daysOfWeather[day].date.getMonth()) {
                todaysWeather = daysOfWeather[day];
            }
        }

        return todaysWeather
    }
}