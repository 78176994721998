export const NEW_APP_VERSION = 'NEW_APP_VERSION';
export const LOAD_META_INFO = 'LOAD_META_INFO';
export const TOGGLE_APP_VIEW = 'TOGGLE_APP_VIEW';
export const TOGGLE_COOKIE_CONSENT = 'TOGGLE_COOKIE_CONSENT';
export const TOGGLE_OFFLINE_MODE = 'TOGGLE_OFFLINE_MODE';

export function updateMetaInfo(meta) {
    return{
        type: 'LOAD_META_INFO',
        meta
    }
}

export function toggleAppView(newView) {
    return{
        type: 'TOGGLE_APP_VIEW',
        newView
    }
}

export function toggleCookieConsent(consent) {
    if (consent == null) {
        consent = true
    }

    consent =  JSON.parse(consent);
    localStorage.setItem('cookieConsent', consent);
    return{
        type: 'TOGGLE_COOKIE_CONSENT',
        consent
    }
}

export function toggleOfflineMode(isOffline) {
    if (isOffline == null) {
        isOffline = true
    }

    isOffline =  JSON.parse(isOffline);
    localStorage.setItem('offlineUsage', isOffline);
    return{
        type: 'TOGGLE_OFFLINE_MODE',
        isOffline
    }
}