
   
import React from 'react';

import ErrorImage from './access_denied.svg';

export default class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        console.log('errorInsfo');
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        console.error(errorInfo);
        console.log('errorInfo');
        // logErrorToMyService(error, errorInfo);
    }

    render() {
        const { hasError } = this.state;
        const { children } = this.props;
        console.log('errorInfo');
        if (hasError) {
            // You can render any custom fallback UI
            return (
                <div className="error-screen">
                    <div className="message">
                        <img src={ErrorImage} alt="error-image" />
                        <p>Something has gone wrong. Please refresh the page.<br/>If this problem persists, Contact us</p>
                        <div className="contact">
                            <a className="contact-twitter" href="https://twitter.com/SalahTimez" target="blank">Twitter</a>
                            <a className="contact-email" href="mailto:salahtimez@deenful.com" target="blank">Email</a>
                        </div>
                    </div>
                </div>
            );
        }

        return children;
    }
}